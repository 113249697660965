import { observer } from 'mobx-react';
import { ReactNode, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { OfferDurationView } from '../../../../components/domain/offer-duration/OfferDurationView';
import { PickStoresModal } from '../../../../components/domain/pick-stores/PickStoresModal';
import { VoucherDurationView } from '../../../../components/domain/voucher-duration/VoucherDurationView';
import { AdType } from '../../../../components/UI/adtype/AdType';
import { AdTypeRadioButtonGroup } from '../../../../components/UI/adtype/AdTypeRadioButtonGroup';
import { Button } from '../../../../components/UI/buttons/regular/Button';
import { RoundButtonGroup } from '../../../../components/UI/buttons/round/RoundButtonGroup';
import { Dropdown } from '../../../../components/UI/dropdown/Dropdown';
import { FileInput } from '../../../../components/UI/file-input/FileInput';
import { InputField } from '../../../../components/UI/input/InputField';
import { Section } from '../../../../components/UI/section/Section';
import { Show } from '../../../../components/UI/show/Show';
import { TextAreaInputField } from '../../../../components/UI/textarea/TextAreaInputField';
import { debounce, FileInputType, useMst } from '../../../../internal';
import { Modal } from '../../../../components/UI/modal/Modal';
import { Cropper } from 'react-cropper';
import { TextArea } from '../../../../components/UI/textarea/TextArea';
import { DropdownOptionGroupsOptions } from '../../../../components/UI/dropdown-optiongroups-options/DropdownOptionGroupsOptions';
import { SelectedOptionGroupsOptions } from '../../../../components/UI/dropdown-optiongroups-options/SelectedOptionGroupsOptions';
import {
  CROPBOX_HEIGHT,
  CROPBOX_WIDTH,
  CROPPER_DIALOG_STYLES,
  CROPPER_DIMENSIONS,
  CROPPER_DIMENSIONS_MOBILE,
} from '../../../../constants/cropper';
import PLUS from '../../../../layouts/images/icon-add.svg';
import styles from '../CreateOffer.module.scss';
import { isMobileView } from '../../../../utils/page';

export const FirstStep = observer((): JSX.Element => {
  const {
    currentPage,
    currentPage: {
      setFileInputIndex,
      fileInputIndex,
      setCrop,
      canSaveOfferDraft,
    },
  } = useMst();

  const onCrop = debounce(
    () => {
      const imageElement: any = cropperRef?.current;
      const cropper: any = imageElement?.cropper;
      setCrop(
        cropper
          .getCroppedCanvas({
            width: CROPBOX_WIDTH,
            height: CROPBOX_HEIGHT,
            minWidth: CROPBOX_WIDTH,
            minHeight: CROPBOX_HEIGHT,
            maxWidth: CROPBOX_WIDTH,
            maxHeight: CROPBOX_HEIGHT,
          })
          .toDataURL()
      );
    },
    250,
    false
  );

  const cropperRef = useRef<HTMLImageElement>(null);
  const fileInputs = currentPage.getFileInputs();

  const { t } = useTranslation();
  const physicalStoresSelected = () =>
    currentPage.getLocationPickModel().value === 'selected' ||
    currentPage.getLocationPickModel().value === 'online-sel';

  return (
    <>
      <div className={styles.FormContainer}>
        <Section model={currentPage.getOfferSection()}>
          <div className={styles.FormContent}>
            <div className={styles.OfferTypeBtnsContainer}>
              <RoundButtonGroup
                model={currentPage.getOfferTypeContainerModel()}
              />
            </div>
            <div className={styles.LocationpPick}>
              <div className={styles.SelectContainer}>
                <Dropdown model={currentPage.getLocationPickModel()} />
              </div>
              <div className={styles.LinkContainer}>
                {physicalStoresSelected() ? (
                  <>
                    <a
                      className={styles.Link}
                      onClick={() => {
                        currentPage.getPickStoresModal().openModal();
                      }}
                    >
                      {t('basic:pick_stores')}
                    </a>
                    <PickStoresModal model={currentPage.getPickStoresModal()} />{' '}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className={styles.CategoryPick}>
              <div className={styles.CategoryContainer}>
                <DropdownOptionGroupsOptions
                  model={currentPage.offerPickers.getCategoryAndSubcategoryPickModel()}
                />
              </div>
              <div className={styles.CategorySelectedOptions}>
                <SelectedOptionGroupsOptions
                  model={currentPage.offerPickers.getCategoryAndSubcategoryPickModel()}
                />
              </div>
              {currentPage.isYettelCategorySelected && (
                <div className={styles.SubcategoryContainer}>
                  <Dropdown model={currentPage.getYettelSegments()} />
                </div>
              )}
            </div>
            <div className={styles.OfferSubform}>
              <div className={styles.OfferDataContainer}>
                <InputField model={currentPage.getOfferNameModel()} />
              </div>
              <div className={styles.OfferDataContainer}>
                <InputField model={currentPage.getLoweredPriceRSDModel()} />
              </div>
              <div className={styles.OfferDataContainer}>
                <InputField model={currentPage.getRegularPriceRSDModel()} />
              </div>
              <div className={`${styles.OfferDataContainer} ${styles.Hidden}`}>
                <InputField model={currentPage.getDiscountModel()} />
              </div>
            </div>
            <div className={styles.OfferSubform}>
              <div className={styles.OfferDataContainer}>
                <InputField model={currentPage.getDiscountPercent()} />
              </div>
            </div>
            <div className={styles.OfferSelectionLine}>
              {currentPage.getOfferTypeContainerModel().value ===
              'OfferWithVoucher' ? (
                <>
                  <div className={styles.OfferDescriptionContainer}>
                    <TextAreaInputField
                      model={currentPage.getOfferDescriptionModel()}
                    />
                  </div>
                  <div className={styles.OfferTermsContainer}>
                    <TextAreaInputField model={currentPage.getOfferTerms()} />
                  </div>
                  <div className={styles.OfferTermsContainer}>
                    <TextArea model={currentPage.getKeywords()} />
                  </div>
                </>
              ) : (
                <div />
              )}
              <div className={styles.OfferSelectionColumn}>
                <label className={styles.Label}>
                  {t('basic:add_offer_image')}
                  <span className={styles.Required}>*</span>
                </label>
                <em className={styles.Em}>{t('basic:primary_image')}</em>
              </div>
              <div className={styles.FileInputContainer}>
                {fileInputs.map((fileInput: FileInputType, index: number) => {
                  return (
                    <div key={index} className={styles.FileInput}>
                      <FileInput
                        model={fileInputs[index]}
                        onFileSizeError={true}
                        ImageWrapper={({
                          children,
                        }: {
                          children: ReactNode;
                        }) => {
                          return (
                            <div className={styles.UploadContainer}>
                              {children}
                            </div>
                          );
                        }}
                        LabelRenderer={() => (
                          <div className={styles.UploadContainer}>
                            <img
                              className={styles.PlusIcon}
                              src={PLUS}
                              alt={''}
                            />
                            <div className={styles.UploadFileDescription}>
                              Dodaj sliku
                            </div>
                            <div className={styles.Description}>
                              Optimizovano za 600x300px .png .jpg
                            </div>
                          </div>
                        )}
                      />
                      {!!fileInputs[index].filesArray.length && (
                        <div className={styles.CropButtons}>
                          <div className={styles.CropEditBtnContainer}>
                            <Button
                              onClick={() => fileInputs[index].clearFiles()}
                            >
                              {t('basic:delete')}
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={styles.FlexOfferSelectionLine}>
              <OfferDurationView model={currentPage.getOfferDuration()} />
              <Show condition={!currentPage.isWithoutVoucher}>
                <VoucherDurationView model={currentPage.getVoucherDuration()} />
              </Show>
              <div className={styles.AdditionalFieldUrlContainer}>
                <div className={styles.LowMarginLabel}>
                  {t('basic:direct_offer_link')}
                  <span className={styles.Required}>
                    {currentPage.directLinkToSiteShouldBeRequired ? '*' : ''}
                  </span>
                </div>
                <InputField model={currentPage.getOfferLinkUrl()} />
                {!currentPage.isWithoutVoucher && (
                  <>
                    <div className={styles.LowMarginLabel}>
                      {t('user_segments:num_coupons')}
                    </div>
                    <div className={styles.CouponsContainer}>
                      <InputField model={currentPage.getNumberOfCoupons()} />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </Section>
      </div>
      <div className={styles.FormContainer}>
        <Section model={currentPage.getAdTypeSection()}>
          <div className={styles.FormContent}>
            <div className={styles.AdTypeButtonContainer}>
              <AdTypeRadioButtonGroup model={currentPage.getAdRadioButtons()} />
            </div>
            <AdType
              standardOffer={currentPage.standardPackageSelected}
              model={currentPage.getTopAd()}
              hideDistanceIcon={true}
              ImageWrapper={({ children }: { children: ReactNode }) => {
                return <div className={styles.UploadContainer}>{children}</div>;
              }}
            />
          </div>
        </Section>
      </div>
      <div className={styles.SubmitContainer}>
        {canSaveOfferDraft() && (
          <div
            onClick={() => currentPage.saveOffer()}
            className={styles.SaveBtn}
          >
            {t('basic:save_offer')}
          </div>
        )}
        <div
          onClick={() => currentPage.submitFirstStepForValidation()}
          className={styles.SubmitBtn}
        >
          {t('basic:continue')}
        </div>
      </div>
    </>
  );
});
