import { getRoot, Instance, types } from 'mobx-state-tree';
import { CATEGORIES, PARTNERS } from '../../../constants/createoffer';
import i18n from '../../../i18n';
import {
  PageModel,
  RootType,
  FileInputModel,
  FileInputType,
  AdTypeModel,
  RadioButtonModel,
  RadioButtonGroupModel,
  ProgressBarModel,
  OfferPickersModel,
  OfferInfoModel,
  OfferTargetingModel,
  OfferSectionModel,
  OfferStepsModel,
  ButtonModel,
  ClearCodesWarningModel,
  ModalModel,
  PartnerLocation,
  Contract,
  Category,
  Ad,
  PageRoutes,
  AdUpdate,
  ConfirmationModalModel,
  RadioButtonModelType,
} from '../../../internal';
import { dateSRLocale, parseDate } from '../../../utils/date';
import {
  createExternalPromise,
  isOfferDraftForSave,
  toAmount,
} from '../../../utils/methods';
import {
  smoothlyScrollToElement,
  smoothlyScrollToPageTop,
} from '../../../utils/scroll';
import { SEGMENTED, STANDARD, TOP, YETTEL_CATEGORY } from '../../../constants';
import { endOfDay, parse } from 'date-fns';
import {
  shouldShowBothPricesFunc,
  shouldShowOnlyDiscountFunc,
} from '../../../components/UI/adtype/conditions';
import { StoreLocationTypes } from '../../../constants/adSettings';
import { PriceCheckType } from '../../../utils/api/ResponseTypes';
import {
  CODE_SPENT,
  EXPIRED,
  MAX_COUPON_NUN,
  PREP,
  PUBLISHED,
  PUBLISHED_SOON,
  REJECTED,
  STOPPED,
} from '../../../constants/ad';
import { OFFER_STEPS } from '../../../constants/offerSteps';
import { validateURL } from '../../../validators/validators';

export function debounce(
  func: (...args: any[]) => void,
  wait: number,
  immediate: boolean
): any {
  let timeout: ReturnType<typeof setTimeout> | null;
  return function (this: any, arg: any[]) {
    const later = () => {
      timeout = null;
      if (!immediate) func.apply(this, arg);
    };
    const callNow = immediate && !timeout;
    timeout && clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(this, arg);
  };
}

export const CreateOfferPageModel = types
  .compose(
    PageModel,
    types.model({
      isPurchaseMade: false,
      activeTab: CATEGORIES,
      fileInputIndex: 0,
      userTargetsCount: 0,
      adIsWindPaySupported: false,
      adIsTopOffer: false,
      adIsFeaturedOffer: false,
      leaveWarningDisabled: false, // disables  dialog which occurs when you try to leave the page
      // submodels, which break up the model logic into multiple files
      offerPickers: types.optional(OfferPickersModel, {}),
      offerInfo: types.optional(OfferInfoModel, {}),
      offerTargeting: types.optional(OfferTargetingModel, {}),
      offerSections: types.optional(OfferSectionModel, {}),
      offerPriceCheck: types.frozen<PriceCheckType | Record<string, number>>(
        {}
      ),
      topOffersRemaining: 0,
    })
  )
  .views((self) => {
    return {
      // LOY-601
      get directLinkToSiteShouldBeRequired(): boolean {
        const val = self?.components?.get?.('LocationPick')?.value;
        return [
          StoreLocationTypes.ONLINE,
          StoreLocationTypes.ALL,
          StoreLocationTypes.ONLINE_SELECTED,
        ].includes(val);
      },
      get offerForSubmit(): Ad {
        return self.offerInfo.offerForSubmit;
      },
      get offerId(): string {
        return self.offerInfo.id;
      },
      get adUpdateForSubmit(): AdUpdate {
        return self.offerInfo.adUpdateForSubmit;
      },
      get isYettelCategorySelected(): boolean {
        const categoryPick = self?.components?.get?.('CategoryPick');
        return (
          (categoryPick?.selectedOption as Category)?.name === YETTEL_CATEGORY
        );
      },
      get codesNeeded(): number {
        return self?.components?.get?.('NumberOfCoupons')?.value
          ? Number(self?.components?.get?.('NumberOfCoupons')?.value)
          : self.userTargetsCount;
      },
      get root(): RootType {
        return getRoot(self);
      },
      get fileInputs(): FileInputType[] {
        return self.componentsArray.filter((component) =>
          component.id.includes('FileInput')
        );
      },
      get shouldShowAdditionalUsers(): boolean {
        // commented out temporarily, see LOY-393
        /*return (
          this.targetsCountExceedsPackage &&
          self?.components?.get?.('AdditionalUsersModel')?.pricesArray?.length
        );*/
        return false;
      },
      get targetsCountExceedsPackage(): boolean {
        // commented out temporarily, see LOY-393
        //return self.userTargetsCount > this.packageUsersAvailable;
        return false;
      },
      get codeTablesEntered() {
        return self?.components?.get('UploadCodesModel')?.uploadCodesCount > 0;
      },
      get codeInputEntered() {
        return self?.components?.get('UploadCodesModel')?.textInputFieldModel();
      },
      get standardPackageSelected(): boolean {
        //return self?.components?.get('PackagePick')?.value === STANDARD;
        // see LOY-393 which requires this
        return true;
      },
      get selectedPurchasedExtensionId(): string | null {
        return this.onlyStandardPackageAvailable
          ? null
          : self?.components?.get('PackagePick')?.selectedOption?.id;
      },
      get onlyStandardPackageAvailable(): boolean {
        return self?.components?.get('PackagePick')?.optionsArray?.length === 1;
      },
      get packageUsersAvailable(): number {
        const data =
          self?.components?.get('PackagePick')?.selectedOption
            ?.targetsRemaining;
        return data ? parseFloat(data) : 0;
      },
      // gets those images which were selected by user
      get imagesArray(): File[] {
        return (self as any).fileInputs
          .filter(
            (fileInput: FileInputType) =>
              fileInput.filesArray && fileInput.filesArray.length
          )
          .map((fileInput: FileInputType) => {
            return fileInput.file;
          });
      },
      get partnerName() {
        return this?.root?.data?.partnerDetails?.name;
      },
      get offerType(): string {
        return self?.components?.get('AdRadioButtons')?.value;
      },
      // used for smartphone view of the offer
      get offerPreview() {
        return {
          discountDescription: `${
            self?.components?.get('Discount')?.value || ''
          }`,
          imagesArray: (self as any).imagesArray.map((image: File) =>
            URL.createObjectURL(image)
          ),
          partner: {
            name: `${this?.root?.data?.partnerDetails?.name || ''}`,
            phone: `${
              this?.root?.data?.partnerDetails?.customer_contact_phone || ''
            }`,
            web: `${this?.root?.data?.partnerDetails?.web || ''}`,
            email: `${
              this?.root?.data?.partnerDetails?.customer_contact_email || ''
            }`,
          },
          name: `${self?.components?.get('OfferName')?.value || ''}`,
          expiration: 'još',
          description: `${
            self?.components?.get('OfferDescription')?.value || ''
          }`,
          conditions: `${self?.components?.get('OfferTerms')?.value || ''}`,
          isWithoutVoucher: this.isWithoutVoucher,
          partnerName: this.adStore,
          loweredPrice: this.adLoweredPriceRSD,
          regularPrice: this.adRegularPriceRSD,
          shouldDisplayShowStoresButton: this.shouldDisplayShowStoresButton,
          expiresIn: this.expiresIn,
          offerType: this.offerType,
        };
      },
      get contract(): Contract {
        return this.root.data.contractDetails;
      },
      get contractFrom(): Date {
        return new Date();
      },
      get contractTo(): Date {
        return new Date(new Date().setFullYear(new Date().getFullYear() + 1));
      },
      get contractId(): string {
        return this?.contract?.id;
      },
      get isOnlineOfferOnly() {
        return self?.components?.get('LocationPick')?.value === 'online';
      },
      get shouldDisplayShowStoresButton() {
        return (
          !this.isOnlineOfferOnly &&
          self?.components.get('LocationTagGroup')?.length > 1
        );
      },
      // Section 'Dodaci i paketi'
      get billSpecificationPreview(): { key: string; value: string }[] {
        const rows = [];
        const locationModel = self?.components?.get('AdditionalLocationModel');
        if (locationModel?.amount) {
          rows.push({
            key: `Kupovina dodatne ${toAmount(
              locationModel?.numberOfAdditionalLocations
            )} lokacije`,
            value: `${toAmount(locationModel.amount)}`,
          });
        }

        const additionalUsers = self?.components?.get('AdditionalUsersModel');
        if (this.targetsCountExceedsPackage && additionalUsers) {
          rows.push({
            key: `Kupovina ${toAmount(
              additionalUsers.numberOfAdditionalUsers
            )} korisnika`,
            value: `${additionalUsers.amount}`,
          });
        }

        rows.push({
          key: 'Ukupno',
          value: `${rows
            .map((elem: any) => parseFloat(elem.value))
            .reduce((a, b) => a + b, 0)}`,
        });
        return rows;
      },
      get fetchedContract() {
        return this.contractId && this.contractFrom && this.contractTo;
      },
      get isWithoutVoucher() {
        return (
          self?.components?.get('OfferTypeContainer')?.value ===
          'OfferWithoutVoucher'
        );
      },
      // if this combination is selected
      // then the second step is disabled
      // and we navigate straight to the third
      get standardPackageWithoutVoucher(): boolean {
        return (
          self?.components?.get('OfferTypeContainer')?.value ===
            'OfferWithoutVoucher' &&
          self?.components?.get('PackagePick')?.value === STANDARD
        );
      },
      get adName(): string {
        return self?.components?.get('OfferName')?.value;
      },
      get adLoweredPriceRSD(): number {
        if (!self?.components?.get('LoweredPriceRSD').value) {
          return 0;
        }
        return parseFloat(self?.components?.get('LoweredPriceRSD').value);
      },
      get adRegularPriceRSD(): number {
        if (!self?.components?.get('RegularPriceRSD').value) {
          return 0;
        }
        return parseFloat(self?.components?.get('RegularPriceRSD').value);
      },
      get adStore(): string {
        return self?.components?.get('Partners')?.selectedOption?.name;
      },
      get adDiscount(): string {
        return self?.components?.get('Discount')?.value;
      },
      get expiresIn(): Date {
        return endOfDay(self?.components?.get('OfferDuration')?.toDate);
      },
      get shouldShowBothPrices() {
        return shouldShowBothPricesFunc(
          this.adRegularPriceRSD,
          this.adLoweredPriceRSD,
          this.adDiscount
        );
      },
      get shouldShowOnlyDiscount() {
        return shouldShowOnlyDiscountFunc(
          this.adRegularPriceRSD,
          this.adLoweredPriceRSD,
          this.adDiscount
        );
      },
      get updateAdId(): string {
        return this?.root?.router?.queryParams?.updateAd;
      },
      get isUpdateMode() {
        return false;
      },
    };
  })
  .actions((self) => {
    return {
      async onPageExit() {
        // To prevent caching
        // in fields on logout
        self.clearData();
        // also don't forget to reset these
        this.resetAdditionalFields();
        self.isPurchaseMade = false;
        self.root.data.setAdDetails(undefined);
      },
      disableLeaveWarning() {
        self.leaveWarningDisabled = true;
      },
      defaultLeaveWarning() {
        return true;
      },
      canSaveOfferDraft() {
        return (
          isOfferDraftForSave(self.root.data?.adDetails?.status) ||
          !window.location.search
        );
      },
      async beforePageExit() {
        if (self.leaveWarningDisabled) {
          return true;
        }
        const { promise, resolve, reject } = createExternalPromise<boolean>();
        this.getLeaveConfirmationModal().setAfterNoCallback(() => {
          reject(false);
        });

        if (this.getOfferNameModel().value && this.canSaveOfferDraft()) {
          this.getLeaveConfirmationModal().open();
        } else {
          return true;
        }

        window.removeEventListener('onbeforeunload', this.defaultLeaveWarning);

        return await promise;
      },
      resetAdditionalFields() {
        self.fileInputIndex = 0;
        self.userTargetsCount = 0;
        self.adIsWindPaySupported = false;
        self.adIsTopOffer = false;
        self.adIsFeaturedOffer = false;
        self.leaveWarningDisabled = false;
      },
      async submitOfferForPriceCheck() {
        return self.root.api.offers.postForPriceCheck(self.offerForSubmit);
      },
      setRemainingTopOffers(remainingTopOffers: number) {
        self.topOffersRemaining = remainingTopOffers;
      },
      async beforePageEnter() {
        if (!self.root.header.partnerName || !self.root.header.partnerId) {
          return Promise.reject('No partner selected');
        }

        window.addEventListener('onbeforeunload', this.defaultLeaveWarning);

        // if (self.root?.router?.queryParams?.updateAd) {
        //   await self.root.api.ad.get(self.root?.router?.queryParams?.updateAd);
        // }

        const responses = await Promise.all([
          self.root.api.category.getAllCategories('abc'),
          self.root.api.partner.get(self.root.header.partnerId),
          self.root.api.user.getUserLocations(),
          self.root.api.partner.topOffersRemaining(self.root.header.partnerId),
          self.root.api.purchases.getPurchases(self.root.header.partnerId),
          self.root.api.contract.getContracts(self.root.header.partnerId),
          self.root.data.catalogs
            ? Promise.resolve()
            : self.root.api.partner.getAllCatalogs(),
        ]);
        if (!responses[5]?.data?.[0]) {
          return Promise.reject('No contract selected');
        } else {
          responses[5].data?.map((contract: any) => {
            self.root.data.setContractDetails(contract);
          });
        }
        this.setRemainingTopOffers(responses[3].data[0]);
        if (responses?.[0]?.data) {
          // if there is a value don't select first option, otherwise do
          // Not selecting the first option will most likely be the case wihh editing inactive/active ads

          self.offerPickers.setCategoriesConfig(
            responses[0].data?.map((category: any) => ({
              id: category.id,
              label: category.name,
              subcategories: category.subcategories,
            }))
          );

          this.getCategoryAndSubcategoryPickModel();
        }

        // if this data hasn't already been fetched (empty options)
        // then fetch it, otherwise leave it alone (no fetching)
        if (!this.getPickStoresModal().allStores.length) {
          await self.root.api.partnerLocation.getPartnerLocations(
            self.root.header.partnerId
          );
        }
        this.handlePartnerLocations();
        this.handlePackages();

        if (
          self.offerPickers.getCategoryAndSubcategoryPickModel().options.length
        ) {
          const categoryId =
            self?.offerPickers?.getCategoryAndSubcategoryPickModel()?.value;
        }

        if (self?.isUpdateMode) {
          this.handleUpdateMode();
        }
      },
      async getSubcategories(categoryId: string) {
        const response = await self?.root.api.subcategory.getAllSubcategories(
          categoryId
        );
        this.handleSubCategories(response);
      },
      setIsPurchaseMade() {
        self.isPurchaseMade = true;
      },
      getActivateButton() {
        return (
          self.components.get('ActivateButton') ||
          self.addComponent(
            ButtonModel.create({
              id: 'ActivateButton',
              label: 'basic:activate',
              onClickCallback: async () => {
                const response = await self.root.api.purchases.newPurchase(
                  self.root.header.partnerId,
                  self.root.data.contractDetails.id
                );
                this.setRemainingTopOffers(response.data.top_addon_value);
                this.setIsPurchaseMade();
              },
            })
          )
        );
      },
      disableNumberOfCoupons() {
        this.getNumberOfCoupons().setDisabled(true);
      },
      handleUpdateMode() {
        // disable relevant stuff on the first step
        this.getOfferDuration().setDisabled(true);
        this.getVoucherDuration().setDisabled(true);
        this.getNumberOfCoupons().setDisabled(true);
        this.getOfferTypeModel().setDisabled(true);

        // this will disable the second step
        self.offerSections.getTargetSection().open();
        self.offerSections.disableTargetSection();
        self.offerSections.disableEnterCodeSection();
      },
      setUserTargetsCount(val: number) {
        self.userTargetsCount = val;
      },
      handlePartnerLocations() {
        this.getPickStoresModal().setStores(
          self?.root?.data?.partnerLocations?.map(
            (store: PartnerLocation, index: number) => ({
              ...store,
              name: `${store.name} ${store.address}`,
              id: store.id ? store.id : `${index + 1}`,
            })
          )
        );
      },
      handleSubCategories(response: any) {
        this.getSubCategoryPickModel().setOptions(
          response.data?.map((option: any) => ({
            data: { ...option },
            value: option.name,
            id: option.id,
          }))
        );
      },
      updatePackagePick() {
        const standardOption = {
          data: {},
          id: STANDARD,
          value: 'Standardni - Članarina',
        };
        const optionList: Map<
          number,
          { data: any; id: string; value: string }
        > = new Map();
        optionList.set(0, standardOption);
        self?.root?.data?.purchasedExtensions?.map((option: any, i: number) => {
          const item: any = {
            data: {
              id: option.id,
              valid_till: option.valid_till,
              targetsRemaining: option.targets_remaining,
              targetsInitial: option.targets_initial,
              name: option.name,
              price: option.price,
              locations: option.locations,
              contractExtensionId: option.contract_extension_id,
            },
            value: `Paket ${i18n.t(
              `packages:${option.name}`
            )} - preostalo ${toAmount(option.targets_remaining)} korisnika - ${
              option.locations
            } lokacija - važi do ${dateSRLocale(new Date(option.valid_till))}`,
            id: 'special' + i,
          };
          optionList.set(i + 1, item);
        });
        this.getPackagePickModel().setOptions(optionList);
      },
      handlePackages() {
        this.updatePackagePick();
        // all methods which change the form depending on package pick
        // are called inside this one 'managePackagePick', as not to clutter
        // this section of the code

        // Temporary commented out, see LOY-393
        //this.managePackagePick();

        // LOY-393, temporary update
        this.manageOfferTypePick();
      },
      // manages all parts of the form which are dependent
      // on picked package
      // This method is currently unused, see LOY-393
      managePackagePick() {
        this.managePackageSections();
        this.manageOfferDuration();
        this.getLocationTagGroup().setMaxNumber(
          parseFloat(this.getPackagePickModel()?.selectedOption?.locations)
        );
        if (self.packageUsersAvailable) {
          this.getNumberOfCoupons().setMax(self.packageUsersAvailable);
        }
      },
      // Temporary, see LOY-393, fulfills much of the same functionality
      // as managePackagePick
      manageOfferTypePick() {
        this.managePackageSectionsTemporary();
        this.manageOfferDuration();
        this.getLocationTagGroup().setMaxNumber(Infinity);
        this.manageNumberOfCoupons();
      },
      manageNumberOfCoupons() {
        this.getNumberOfCoupons().setMax(MAX_COUPON_NUN);
        this.getNumberOfCoupons().setCurrentMessage('');
      },
      // manages targeting and location picking, depending on the pick, see LOY-393
      managePackageSectionsTemporary() {
        // temporary, see LOY-393
        if (this.getOfferTypeModel().value !== SEGMENTED) {
          self.offerSections.getTargetSection().open();
        }
      },
      initSections() {
        self.offerPickers.parent = self;
        self.offerInfo.parent = self;
        self.offerTargeting.parent = self;
        self.offerSections.parent = self;
      },
      // manages targeting and location picking, depending on the pick
      managePackageSections() {
        if (self.offerPickers.getOfferTypeModel().value === STANDARD) {
          self.offerSections.getTargetSection().open();
        } else {
          self.offerSections.getTargetSection().close();
          self.offerSections.getEnterCodeSection().close();
        }
      },
      manageOfferDuration() {
        if (
          parseDate(this.getOfferDuration().dateFrom) !==
          parse(new Date().toString(), 'yyyy-MM-dd', new Date())
        ) {
          return;
        }
        if (self.standardPackageSelected) {
          const minDate = self.contractFrom;
          const maxDate = self.contractTo;
          const maxDurationText = `Članarina važi do ${dateSRLocale(
            maxDate
          )} (datum isteka ugovora)`;
          this.getOfferDuration().setPackageDuration(
            minDate,
            maxDate,
            maxDurationText
          );
        } else {
          const option = this.getPackagePickModel().selectedOption;
          const maxDate = parseDate(option.valid_till);
          const minDate = new Date();
          const maxDurationText = `Paket traje do ${dateSRLocale(maxDate)}`;
          this.getOfferDuration().setPackageDuration(
            minDate,
            maxDate,
            maxDurationText
          );
        }
      },
      async afterCreate() {
        this.initSections();
      },
      getFileInputs() {
        if (!self?.fileInputs || !self?.fileInputs?.length) {
          this.initFileInputs();
        }
        return self.fileInputs;
      },
      initFileInputs() {
        Array.from(Array(4).keys()).forEach((index: number) => {
          self.addComponent(
            FileInputModel.create({
              id: `FileInput${index}`,
              label: 'Za koje lokacije ponuda važi',
              maxNumberOfFiles: 1,
              onAdd: () => {
                this.setFileInputIndex(index);
              },
            })
          );
        });
      },
      getOfferSteps() {
        return (
          self.components.get('OfferSteps') ||
          self.addComponent(
            OfferStepsModel.create({
              id: 'OfferSteps',
              beforeStepSelectedCallback: (stepId: string) => {
                if (stepId === OFFER_STEPS.SECOND_STEP) {
                  return this.submitFirstSection();
                } else if (stepId === OFFER_STEPS.THIRD_STEP) {
                  return this.submitThirdSection();
                }
                return true;
              },
            })
          )
        );
      },
      getOfferTypeContainerModel() {
        return self.offerPickers.getOfferTypeContainerModel();
      },
      getPackagePickModel() {
        return self.offerPickers.getPackagePickModel();
      },
      getOfferTypeModel() {
        return self.offerPickers.getOfferTypeModel();
      },
      getYettelSegments() {
        return self.offerPickers.getYettelSegments();
      },
      getLocationPickModel() {
        return self.offerPickers.getLocationPickModel();
      },
      getCategoryPickModel() {
        return self.offerPickers.getCategoryPickModel();
      },
      getCategoryAndSubcategoryPickModel() {
        return self.offerPickers.getCategoryAndSubcategoryPickModel();
      },
      getSubCategoryPickModel() {
        return self.offerPickers.getSubCategoryPickModel();
      },
      handleNewSelectionCategoriesAndSubcategoriesPick(
        selectedOptionGroupsIds: string[],
        selectedOptionsIds: string[]
      ) {
        return self.offerPickers.handleNewSelectionCategoriesAndSubcategoriesPick(
          selectedOptionGroupsIds,
          selectedOptionsIds
        );
      },
      setCrop(crop: string) {
        self?.fileInputs?.[self.fileInputIndex]?.setCrop(crop);
      },
      setFileInputIndex(fileInputIndex: number) {
        self.fileInputIndex = fileInputIndex;
      },
      getOfferNameModel() {
        return self.offerInfo.getOfferNameModel();
      },
      getLoweredPriceRSDModel() {
        return self.offerInfo.getLoweredPriceRSDModel();
      },
      getOfferId() {
        return self.offerInfo.id;
      },
      getRegularPriceRSDModel() {
        return self.offerInfo.getRegularPriceRSDModel();
      },
      getDiscountModel() {
        return self.offerInfo.getDiscountModel();
      },
      getDiscountPercent() {
        return self.offerInfo.getDiscountPercent();
      },
      getCodeCount: () => {
        return self.offerInfo.getCodeCount();
      },
      getOfferDescriptionModel() {
        return self.offerInfo.getOfferDescriptionModel();
      },
      getOfferTerms() {
        return self.offerInfo.getOfferTerms();
      },
      getKeywords() {
        return self.offerInfo.getKeywords();
      },
      getPickStoresModal() {
        return self.offerPickers.getPickStoresModal();
      },
      getOfferDuration() {
        return self.offerInfo.getOfferDuration();
      },
      getVoucherDuration() {
        return self.offerInfo.getVoucherDuration();
      },
      getOfferLinkUrl() {
        return self.offerInfo.getOfferLinkUrl();
      },
      getEnterCodesModel() {
        return self.offerTargeting.getEnterCodesModel();
      },
      getLeaveConfirmationModal() {
        return (
          self.components.get('LeaveConfirmationModal') ||
          self.addComponent(
            ConfirmationModalModel.create({
              id: 'LeaveConfirmationModal',
              labelNo: 'basic:cancel',
              labelYes: 'basic:yes',
              afterYes: this.saveAd,
              modal: ModalModel.create({
                id: 'modal',
                opened: false,
                showCloseButton: false,
              }),
            })
          )
        );
      },
      getBuyTargeting() {
        return (
          self.components.get('BuyLocation') ||
          self.addComponent(
            ButtonModel.create({
              id: 'BuyLocation',
              label: i18n.t('basic:buy_location_only_standard'),
              onClickCallback: () => {
                this.backToOfferTypePick();
              },
            })
          )
        );
      },
      getAdRadioButtons() {
        return (
          self.components.get('AdRadioButtons') ||
          self.addComponent(
            RadioButtonGroupModel.create({
              id: 'AdRadioButtons',
              initialValue: STANDARD,
              buttons: {
                top: RadioButtonModel.create({
                  id: TOP,
                  isEnabledFunc: (selfie: RadioButtonModelType) => {
                    if (self.root.data.isPromoPackage) return true;
                    if (self.isUpdateMode && !selfie.isSelected) {
                      return false;
                    }
                    return (
                      self.root.data.isPayAsYouGo ||
                      !self.root.data.isPromoPeriodOver ||
                      self.topOffersRemaining !== 0
                    );
                  },
                  label: `Top oglas ${
                    self.root.data.isPayAsYouGo &&
                    self.root.data.isPromoPeriodOver
                      ? ' + ' +
                        self.root.data.currentCatalog?.price_top +
                        ' RSD *dan'
                      : ''
                  }`,
                }),
                standard: RadioButtonModel.create({
                  isEnabledFunc: (selfie: RadioButtonModelType) => {
                    if (self.root.data.isPromoPackage) return true;
                    return !(self.isUpdateMode && !selfie.isSelected);
                  },
                  id: STANDARD,
                  label: 'Besplatan oglas',
                }),
              },
            })
          )
        );
      },
      getTopAd() {
        return (
          self.components.get('AdTypeModel') ||
          self.addComponent(
            AdTypeModel.create({
              id: 'AdTypeModel',
              name: self.adName,
              loweredPrice: self.adLoweredPriceRSD,
              regularPrice: self.adRegularPriceRSD,
              discount: self.adDiscount,
              currency: ' RSD', //TODO add currency
              store: self.adStore,
              expiration: 'Jos 3 dana', // TODO add self.adExpiration
              distance: '1 km', // TODO add  self.adDistance
              isWindPaySupported: self.adIsWindPaySupported,
              isTopOffer: false, //TODO add handling
              isFeaturedOffer: false, //TODO add handling
            })
          )
        );
      },
      setAdIsWindPaySupported(value: boolean) {
        self.adIsWindPaySupported = value;
      },
      getUserLocationDropdown() {
        return self.offerTargeting.getUserLocationDropdown();
      },
      getLocationTagGroup() {
        return self.offerTargeting.getLocationTagGroup();
      },
      getGenderDropdown() {
        return self.offerTargeting.getGenderDropdown();
      },
      getFromAge() {
        return self.offerTargeting.getFromAge();
      },
      getToAge() {
        return self.offerTargeting.getToAge();
      },
      getUsersPickedSimilarOffersDropdown() {
        return self.offerTargeting.getUsersPickedSimilarOffersDropdown();
      },
      getNumberOfCoupons() {
        return self.offerTargeting.getNumberOfCoupons();
      },
      getUserSegments() {
        return self.offerTargeting.getUserSegments();
      },
      getMobileNetworkSpending() {
        return self.offerTargeting.getMobileNetworkSpending();
      },
      getAreaDropdown() {
        return self.offerTargeting.getAreaDropdown();
      },
      getAdditionalLocation() {
        return self.offerTargeting.getAdditionalLocation();
      },
      getAdditionalUsers() {
        return self.offerTargeting.getAdditionalUsers();
      },
      getBillSpecificationSection() {
        return self.offerSections.getBillSpecificationSection();
      },
      getPaymentSummarySection() {
        return self.offerSections.getPaymentSummarySection();
      },
      getOfferSection() {
        return self.offerSections.getOfferSection();
      },
      getAdTypeSection() {
        return self.offerSections.getAdTypeSection();
      },
      getLocationPickSection() {
        return self.offerSections.getLocationPickSection();
      },
      getTargetSection() {
        return self.offerSections.getTargetSection();
      },
      getOfferTypeSection() {
        return self.offerSections.getOfferTypeSection();
      },
      getEnterCodeSection() {
        return self.offerSections.getEnterCodeSection();
      },
      disableEnterCodeSection() {
        return self.offerSections.disableEnterCodeSection();
      },
      getUserPreviewSection() {
        return self.offerSections.getUserPreviewSection();
      },
      resetTargetAndLocationPick() {
        return self.offerSections.resetTargetAndLocationPick();
      },
      setTargetSectionContinueStatus() {
        return self.offerTargeting.setTargetSectionContinueStatus();
      },
      getProgressBarModel() {
        return (
          self.components.get('progressBar') ||
          self.addComponent(
            ProgressBarModel.create({
              id: 'progressBar',
            })
          )
        );
      },
      getLocationPickEdit() {
        return (
          self.components.get('LocationPickEdit') ||
          self.addComponent(
            ButtonModel.create({
              id: 'LocationPickEdit',
              label: 'basic:change',
              onClickCallback: () => {
                this.enableLocationPickSection();
                this.getLocationPickSection().setEditMode(false);
              },
            })
          )
        );
      },
      getLocationPickContinue() {
        return (
          self.components.get('LocationPickContinue') ||
          self.addComponent(
            ButtonModel.create({
              id: 'LocationPickContinue',
              label: 'basic:confirm_location',
              disabled: true,
              onClickCallback: async () => {
                await this.updateTargetCount();
                // let locationText = '';
                // if (this.getLocationTagGroup().length === 1) {
                //   locationText = i18n.t('basic:added_location');
                // } else if (
                //   this.getLocationTagGroup().length === 2 ||
                //   this.getLocationTagGroup().length === 3 ||
                //   this.getLocationTagGroup().length === 4
                // ) {
                //   locationText = i18n.t('basic:added_location_sr');
                // } else {
                //   locationText = i18n.t('basic:added_locations');
                // }
                // this.disableLocationPickSection();
                // this.getLocationPickSection().setMessage(
                //   templateReplace(
                //     locationText,
                //     'x',
                //     `${this.getLocationTagGroup().length}`
                //   )
                // );
                // this.getLocationPickSection().setEditMode(true);
                // this.getTargetSection().open();
              },
            })
          )
        );
      },
      getUserTargetCheckboxGroup() {
        return self.offerSections.getTargetSectionCheckbox();
      },
      getTargetGroupEdit() {
        return (
          self.components.get('TargetGroupEdit') ||
          self.addComponent(
            ButtonModel.create({
              id: 'TargetGroupEdit',
              label: 'basic:change',
              onClickCallback: () => {
                this.enableTargetSection();
              },
            })
          )
        );
      },
      async updateTargetCount() {
        // we fetch the number of user targets from the BE
        // in order to see how many users we can target
        await self?.root.api.user.getUserTargets(
          this.getLocationTagGroup().valsStr,
          this.getGenderDropdown().value?.trim?.(),
          parseInt(this.getFromAge().value?.trim?.()),
          parseInt(this.getToAge().value?.trim?.()),
          this.getUsersPickedSimilarOffersDropdown().value,
          this.getCategoryAndSubcategoryPickModel().selectedOptionsArray,
          this.getUserSegments().selectedIdsStr,
          this.getMobileNetworkSpending().selectedIdsStr,
          this.getAreaDropdown()?.value?.trim?.()
        );

        this.setUserTargetsCount(
          Number(self?.root?.data?.userTargets?.[0]?.targets)
        );
      },
      getTargetGroupContinue: function () {
        return (
          self.components.get('TargetGroupContinue') ||
          self.addComponent(
            ButtonModel.create({
              id: 'TargetGroupContinue',
              label: 'basic:confirm_targeting',
              isEnabledFunc: (selfie: any) =>
                this.getOfferTypeModel().value === SEGMENTED &&
                !self.isUpdateMode &&
                this.getLocationTagGroup().length > 0,
              onClickCallback: async () => {
                await this.updateTargetCount();
                // if the desired coupon number is higher than available targets
                // then we show the alert which says that we can't possibly
                // target more users than there physically are
                // if (self.codesNeeded > self.userTargetsCount) {
                //   self.root.showFailure(
                //     i18n.t('alert:more_coupons_than_users')
                //   );
                //   return;
                // }
                // this will load the relevant price list, so we can see
                // how much will the additional targets cost
                // we also set max number of additional users
                // if (self.targetsCountExceedsPackage) {
                //   self.root.api.contract.getContractAddons(
                //     self?.contractId,
                //     'target'
                //   );
                //   const maxAdditionalValue =
                //     self.userTargetsCount - self.packageUsersAvailable;
                //   this.getAdditionalUsers()?.numberOfAdditionalUsersField?.setValue?.(
                //     maxAdditionalValue
                //   );
                //   this.getAdditionalUsers().setMaxNumber(maxAdditionalValue);
                // }
                // If this conddition not true, the second section
                // of the second step won't turn into edit mode
                // allowing the user to confirm if they want to
                // accept the data which is on the screen
                // if (
                //   self.targetsCountExceedsPackage && !this?.getAdditionalUsers()?.shouldContinue
                // ) {
                //   this.getAdditionalUsers().setShouldContinue(true);
                //   this.enableTargetSection();
                // } else {
                // this.getEnterCodeSection().open();
                // this.getTargetSection().setEditMode(true);
                // }
              },
            })
          )
        );
      },
      getClearCodesWarning() {
        return (
          self.components.get('ClearCodesWarning') ||
          self.addComponent(
            ClearCodesWarningModel.create({
              id: 'ClearCodesWarning',
              modal: ModalModel.create({
                id: 'ClearCodesModal',
                opened: false,
                showCloseButton: true,
              }),
              continueButton: ButtonModel.create({
                id: 'ContinueButtonClearCodes',
                label: 'basic:continue',
              }),
            })
          )
        );
      },
      getSaveOfferModal() {
        return (
          self.components.get('SaveConfirmationModal') ||
          self.addComponent(
            ConfirmationModalModel.create({
              id: 'SaveConfirmationModal',
              labelNo: 'basic:no',
              labelYes: 'basic:yes',
              afterYes: this.saveAd,
              modal: ModalModel.create({
                id: 'modal',
                opened: false,
                showCloseButton: false,
              }),
            })
          )
        );
      },
      enableTargetSection() {
        return self.offerSections.setEditMode(true);
      },
      enableLocationPickSection() {
        return self.offerSections.enableLocationPickSection();
      },
      getSuccessSaveModal() {
        return (
          self.components.get('SaveSuccessModal') ||
          self.addComponent(
            ConfirmationModalModel.create({
              id: 'SaveSuccessModal',
              labelYes: 'basic:yes',
              text: 'Oglas uspešno sačuvan',
              modal: ModalModel.create({
                id: 'modal',
                opened: false,
                showCloseButton: false,
              }),
            })
          )
        );
      },
      getBuyAndSendForApproval() {
        return (
          self.components.get('BuyAndSendForApprovalBtn') ||
          self.addComponent(
            ButtonModel.create({
              id: 'BuyAndSendForApprovalBtn',
              label: 'offer:buy_and_send_for_approval',
              onClickCallback: () => {
                this.sendForApproval();
              },
            })
          )
        );
      },
      getSendForApproval() {
        return (
          self.components.get('SendForApprovalBtn') ||
          self.addComponent(
            ButtonModel.create({
              id: 'SendForApprovalBtn',
              label: 'offer:send_for_approval',
              onClickCallback: () => {
                this.sendForApproval();
              },
            })
          )
        );
      },
      saveAd() {
        // const codeType =
        //   self?.components.get('UploadCodesModel')?.codeTypeDropdown?.value;
        // if (codeType === CODE_TYPE_PERSONALIZED && !self?.codeTablesEntered) {
        //   rootInstance.showFailure(i18n.t('forms:codes_must_be_imported'));
        //   return;
        // }
        const offerName = this.getOfferNameModel().value;
        const offerUrl = this.getOfferLinkUrl().value;
        const locationPick = this.getLocationPickModel().value;
        const categoryPick = this.getCategoryAndSubcategoryPickModel();
        const isUrlNeeded =
          locationPick === 'all' ||
          locationPick === 'online' ||
          locationPick === 'online-sel';
        if (!offerName) {
          self?.root.showFailure(i18n.t('forms:offer_name_missing'));
          return;
        }
        if (!categoryPick.selectedOptionsArray.length) {
          self?.root.showFailure(
            i18n.t('offer:must_choose_category_subcategory')
          );
          return;
        }
        if (!offerUrl && isUrlNeeded) {
          self?.root.showFailure(i18n.t('forms:url_missing'));
          return;
        }
        if (offerUrl && isUrlNeeded && !validateURL(offerUrl)) {
          self?.root.showFailure(i18n.t('forms:invalid_url'));
          return;
        }
        self?.root.api.offers.saveAd(self.offerForSubmit).then((r: any) => {
          smoothlyScrollToPageTop();
          this.prepareForSuccessNavigation();
          self.root.router.navigate({
            newView: PageRoutes.PartnerOffers.id,
            shouldReplace: true,
          });
        });
      },
      saveAdAndStay() {
        self?.root.api.offers.saveAd(self.offerForSubmit).then((r: any) => {
          this.getSuccessSaveModal().open();
        });
      },
      sendForApproval() {
        if (this.submitThirdSection()) {
          let isSubmitted = [
            CODE_SPENT,
            EXPIRED,
            STOPPED,
            REJECTED,
            PREP,
          ].includes(self.root.data?.adDetails?.status);
          if (!self.root.data?.adDetails?.status) {
            isSubmitted = true;
          }
          const isPost = [CODE_SPENT, EXPIRED, STOPPED, REJECTED].includes(
            self.root.data?.adDetails?.status
          );
          if (!self.root.data?.adDetails?.status) {
            isSubmitted = true;
          }
          (self.root.data?.adDetails?.status === PUBLISHED ||
          self.root.data?.adDetails?.status === PUBLISHED_SOON
            ? self.root.api.adUpdate.update(self.adUpdateForSubmit)
            : self?.root.api.offers.saveAd(
                self.offerForSubmit,
                isSubmitted,
                isPost
              )
          ).then((r: any) => {
            smoothlyScrollToPageTop();
            this.prepareForSuccessNavigation();
            self.root.router.navigate({
              newView: PageRoutes.CreateOfferSuccess.id,
              shouldReplace: true,
            });
          });
        }
      },
      // backups relevant data to create offer success model before the create offer one is cleared
      prepareForSuccessNavigation() {
        const { currentPage, pages } = getRoot(self) as any;
        const successPage = pages.get(PageRoutes.CreateOfferSuccess.id);
        successPage.setStandardPackageSelected(
          currentPage.standardPackageSelected
        );
        successPage.setNumberOfTargetedUsers(
          self.offerInfo.calculateMaxTarget()
        );
        currentPage.disableLeaveWarning();
      },
      getBuyAdditionalPackage() {
        return self.offerTargeting.getBuyAdditionalPackage();
      },
      updateDisabledLocations() {
        return self.offerTargeting.updateDisabledLocations();
      },
      backToPackagePick() {
        this.getOfferSteps().selectStep('1');
        this.getPackagePickModel().setAlwaysShowOptions(true);
        setTimeout(() => {
          smoothlyScrollToElement(`select_${this.getPackagePickModel().id}`);
        }, 200);
      },
      backToOfferTypePick() {
        this.getOfferTypeModel().setAlwaysShowOptions(true);
      },
      submitFirstSection(): boolean {
        return self.offerSections.submitFirstSection();
      },
      saveOffer() {
        this.getSaveOfferModal().open();
      },
      submitSecondSection(): boolean {
        return self.offerSections.submitSecondSection();
      },
      submitThirdSection(): boolean {
        return self.offerSections.submitThirdSection();
      },
      validateFirstSection(): boolean {
        return self.offerSections.validateFirstSection();
      },
      validateSecondSection(): boolean {
        return self.offerSections.validateSecondSection();
      },
      async submitFirstStepForValidation() {
        if (this.validateFirstSection()) {
          if (self.standardPackageWithoutVoucher) {
            const response: PriceCheckType =
              await this.submitOfferForPriceCheck();
            this.setOfferPriceCheck(response);

            this.getOfferSteps().selectStep(OFFER_STEPS.THIRD_STEP);
          } else {
            this.getOfferSteps().selectStep(OFFER_STEPS.SECOND_STEP);
          }
        }
      },
      async submitSecondStepForValidation() {
        if (
          this.getOfferTypeModel().value === SEGMENTED &&
          this.getLocationTagGroup().length === 0
        ) {
          self.root.showFailure(i18n.t('alert:confirm_targeting'));
          return;
        }
        const response: PriceCheckType = await this.submitOfferForPriceCheck();
        this.setOfferPriceCheck(response);

        this.getOfferSteps().selectStep('3');
      },
      setOfferPriceCheck(priceCheck: PriceCheckType) {
        self.offerPriceCheck = priceCheck;
      },
      backToFirstStep() {
        this.getOfferSteps().selectStep('1');
      },
      backToSecondStep() {
        this.getOfferSteps().selectStep('2');
      },
    };
  })
  .named('CreateOfferPageModel');

export type CreateOfferPageType = Instance<typeof CreateOfferPageModel>;
