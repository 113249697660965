import {
  ButtonModel,
  DropdownModel,
  FileInputModel,
  FileInputType,
  InfoFieldModel,
  AdministratorsModel,
  PackageSelectionModel,
  PageModel,
  RootType,
  TabModel,
  TabsModel,
  TextInputModel,
  PhysicalStoreLocationsModel,
  ModalModel,
  Contract,
  StoreLocationModalModel,
  DeleteLocationModalModel,
  ConfirmationModalModel,
  // ApiListResponse,
  CheckboxModel,
  DropdownOptionModel,
  CheckboxGroupModel,
  ShoppingMall,
} from '../../internal';
import { getRoot, Instance, types } from 'mobx-state-tree';
import i18n from '../../i18n';
import {
  ADMINISTRATORS,
  HEADQUARTERS,
  PACKAGES,
  IS_SHOPPING_MALL,
  STORE_LOCATIONS,
} from '../../constants/companyprofile';
import { validateEmail, validateURL } from '../../validators/validators';
import { STANDARD } from '../../constants';
import debounce from 'lodash.debounce';
import {
  PAY_AS_YOU_GO,
  SUBSCRIPTION_WITH_CONTRACT,
  SUBSCRIPTION_WITHOUT_CONTRACT,
} from '../../utils/constants';

export const CompanyProfileModel = types
  .compose(
    PageModel,
    types.model({
      isEditMode: false,
      isContractRequestSent: false,
      isMonthlyRequestSent: false,
      isPayAsYouGoRequestSent: false,
      catalogs: types.frozen(),
      packageName: '',
      request: types.frozen(),
    })
  )
  .views((self) => {
    return {
      get root(): RootType {
        return getRoot(self);
      },
      get fileInputs(): FileInputType[] {
        return self.componentsArray.filter((component) =>
          component.id.includes('FileInput')
        );
      },
      get contract(): Contract {
        return this.root.data.contractDetails;
      },
      get isSubscriptionWithNoContractRequested() {
        return (
          self.request?.name === SUBSCRIPTION_WITHOUT_CONTRACT &&
          ['requested', 'submitted'].includes(self.request?.status)
        );
      },
      get isPayAsYouGoRequested() {
        return (
          self.request?.name === PAY_AS_YOU_GO &&
          ['requested', 'submitted'].includes(self.request?.status)
        );
      },
      get isSubscriptionWithContractRequested() {
        return (
          self.request?.name === SUBSCRIPTION_WITH_CONTRACT &&
          ['requested', 'submitted'].includes(self.request?.status)
        );
      },
      get isRequestSubmitted(): boolean {
        return ['requested', 'submitted'].includes(self.request?.status);
      },
      get isRequestApproved(): boolean {
        return self.request?.status === 'approved';
      },
    };
  })
  .actions((self) => {
    return {
      setPackageName(name: string) {
        self.packageName = name;
      },
      setRequest(request: any) {
        self.request = request;
      },
      getConfirmSendRequestModal() {
        return (
          self.components.get('ConfirmSendRequestModal') ||
          self.addComponent(
            ModalModel.create({
              id: 'ConfirmSendRequestModal',
              label: 'ConfirmSendRequestModal',
              opened: false,
              showCloseButton: true,
            })
          )
        );
      },
      getSendRequestModal() {
        return (
          self.components.get('SendRequestModal') ||
          self.addComponent(
            ModalModel.create({
              id: 'SendRequestModal',
              label: 'SendRequestModal',
              opened: false,
              showCloseButton: true,
            })
          )
        );
      },
      getAdditionActivatedModal() {
        return (
          self.components.get('AdditionActivatedModal') ||
          self.addComponent(
            ModalModel.create({
              id: 'AdditionActivatedModal',
              label: 'AdditionActivatedModal',
              opened: false,
              showCloseButton: true,
            })
          )
        );
      },
      async onPageExit() {
        self.clearData();
        self.isEditMode = false;
      },
      setCatalogs(catalogs: any) {
        self.catalogs = catalogs;
      },
      async beforePageEnter() {
        if (!self.root.header.partnerName || !self.root.header.partnerId) {
          return Promise.reject('No partner selected');
        } else {
          // will fetch purchased extensions that we need for
          // so we can fill active packages
          await Promise.all([
            self.root.api.contract.getContracts(self.root.header.partnerId),
            self.root.api.partnerLocation.getPartnerLocations(
              self.root.header.partnerId
            ),
            self.root.api.partner.getAllCatalogs(),
            self.root.api.partnerLocation.getShoppingMalls(),
          ]).then((responses) => {
            responses[0].data?.map((contract: any) => {
              self.root.data.setContractDetails(contract);
            });
            this.setCatalogs(responses[2].data);
          });

          // this has to be resolved separately, because this call depends on
          // previous two being resolved
          /*await self.root.api.contract.getContractExtensions(
            self.root.data.contractDetails.id
          );*/
        }
        this.loadAdmins();
        self.root.api.purchases.getPurchases(self.root.header.partnerId);
        this.manageAdditionalPackages();
        this.manageActivePackages();
        this.manageStoreLocations();
        this.getShoppingMallLocations();
        await Promise.all([
          this.getCategoryModel().options.size
            ? Promise.resolve()
            : self.root.api.category.getAllCategories('abc'),
        ]).then((response: any) => {
          if (response?.[0]?.data) {
            this.getCategoryModel().setOptions([
              { data: {}, value: '', id: ' ' },
              ...response[0].data?.map((option: any) => ({
                data: { ...option },
                value: option.name,
                id: option.id,
              })),
            ]);
          }
        });
        await self.root.api.partner.get(self.root.header.partnerId);
        await self.root.api.contract
          .getContractRequestsDetails(self?.root?.data?.partnerDetails?.id)
          .then((res: any) => {
            this.setRequest(res.data);
          });
        self.root.data.partnerDetails.category &&
          this.getCategoryModel().handleNewSelection(
            self.root.data.partnerDetails.category
          );
        this.getCategoryModel().setDisabled(true);
        self.root.data.partnerDetails.segmentation &&
          this.getSegmentModel().handleNewSelection(
            self.root.data.partnerDetails.segmentation
          );
      },
      async loadAdmins() {
        await self.root.api.admin.getAdminsByPartner(
          self.root.header.partnerId
        );

        this.getAdministrators().setElements(self.root.data.otherPartnerAdmins);
      },
      setEditMode(edit: boolean) {
        self.isEditMode = edit;
      },
      manageStoreLocations() {
        this.getStoreLocations().setLocations(self.root.data.partnerLocations);
      },
      getShoppingMallLocations() {
        this.getStoreLocations().setShoppingMalls(self.root.data.shoppingMalls);
      },
      manageAdditionalPackages() {
        this.getPackageSelection().setAdditionalPackages(
          self.root.data.contractExtensions
        );
      },
      manageActivePackages() {
        this.getPackageSelection().setActivePackages([
          {
            id: STANDARD,
            name: 'Članarina',
            valid_till: self?.contract?.to,
            price: '0',
          },
          ...self.root.data.purchasedExtensions,
        ]);
      },
      getTabs() {
        return (
          self.components.get('CompanyProfileTabs') ||
          self.addComponent(
            TabsModel.create({
              id: 'CompanyProfileTabs',
              selectedTab: HEADQUARTERS,
              tabs: {
                headquarters: TabModel.create({
                  id: HEADQUARTERS,
                  name: i18n.t('tabs:headquarters'),
                }),
                store_locations: TabModel.create({
                  id: STORE_LOCATIONS,
                  name: i18n.t('tabs:store_locations'),
                }),
                packages: TabModel.create({
                  id: PACKAGES,
                  name: i18n.t('tabs:packages'),
                }),
                // temporarily hidden
                /*package_selection: TabModel.create({
                  id: PACKAGE_SELECTION,
                  name: i18n.t('tabs:package_selection'),
                }),*/
                administrators: TabModel.create({
                  id: ADMINISTRATORS,
                  name: i18n.t('tabs:administrators'),
                }),
              },
              onChangeCallback: () => {
                //
              },
            })
          )
        );
      },
      getCategoryModel() {
        return (
          self?.components.get('CategoryModel') ||
          self?.addComponent(
            DropdownModel.create({
              id: 'CategoryModel',
              label: 'basic:product_category',
              isRequired: true,
              options: {},
            })
          )
        );
      },
      getContactPhoneResponsiblePerson() {
        return (
          self?.components.get('ContactPhoneResponsiblePerson') ||
          self?.addComponent(
            TextInputModel.create({
              id: 'ContactPhoneResponsiblePerson',
              label: 'company_profile:responsible_person_phone',
              isRequired: true,
              value: self.root.data.partnerDetails.phone,
            })
          )
        );
      },
      getTelenorAgentEmail() {
        return (
          self?.components.get('TelenorAgentEmail') ||
          self?.addComponent(
            TextInputModel.create({
              id: 'TelenorAgentEmail',
              label: 'company_profile:email_telenor_agent',
              isRequired: true,
              value: self.root.data.partnerDetails.email || '',
              validators: {
                validateEmail,
              },
            })
          )
        );
      },
      getWebAddress() {
        return (
          self?.components.get('WebAddress') ||
          self?.addComponent(
            TextInputModel.create({
              id: 'WebAddress',
              label: 'company_profile:web_address',
              isRequired: true,
              validators: {
                validateURL: validateURL,
              },
              value: self.root.data.partnerDetails.web || '',
            })
          )
        );
      },
      getEmailForConsumersContact() {
        return (
          self?.components.get('EmailForConsumersContact') ||
          self?.addComponent(
            TextInputModel.create({
              id: 'EmailForConsumersContact',
              label: 'company_profile:email_for_contact_with_consumers',
              isRequired: true,
              value: self.root.data.partnerDetails.customer_contact_email || '',
              validators: {
                validateEmail,
              },
            })
          )
        );
      },
      getPhoneForConsumersContact() {
        return (
          self?.components.get('PhoneForConsumersContact') ||
          self?.addComponent(
            TextInputModel.create({
              id: 'PhoneForConsumersContact',
              label: 'company_profile:contact_phone_for_consumers',
              isRequired: true,
              value: self.root.data.partnerDetails.customer_contact_phone || '',
            })
          )
        );
      },
      getCompanyNameOnAds() {
        return (
          self?.components.get('CompanyNameOnAds') ||
          self?.addComponent(
            TextInputModel.create({
              id: 'CompanyNameOnAds',
              label: 'company_profile:company_name_on_ads',
              isRequired: true,
              value: self.root.data.partnerDetails.name || '',
            })
          )
        );
      },
      getSegmentModel() {
        return (
          self?.components.get('SegmentModel') ||
          self?.addComponent(
            DropdownModel.create({
              id: 'SegmentModel',
              label: 'basic:segmentation',
              isRequired: true,
              initialValue: ' ',
              placeholder: 'basic:pick',
              options: {
                a: DropdownOptionModel.create({
                  id: 'A brend',
                  value: 'A brend',
                }),
                b: DropdownOptionModel.create({
                  id: 'B brend',
                  value: 'B brend',
                }),
                soho: DropdownOptionModel.create({
                  id: 'SoHo',
                  value: 'Soho',
                }),
              },
            })
          )
        );
      },
      getSaveButton() {
        return (
          self.components.get('SaveButton') ||
          self.addComponent(
            ButtonModel.create({
              id: 'SaveButton',
              label: 'basic:save',
              onClickCallback: () => {
                if (self.runValidators()) {
                  self.root.api.partner.updatePartner();
                  this.setEditMode(true);
                }
              },
            })
          )
        );
      },
      async requestNewTariffPackage(name: string) {
        const newTariffPackage = self.catalogs.find(
          (obj: { calculator: string; id: string }) => obj.calculator === name
        );
        const data = {
          catalog_id: newTariffPackage.id,
          partner_id: self.root.data.partnerDetails.id,
        };
        const response = await self.root.api.contract.requestNewTariffPackage(
          data
        );
        this.setRequest(response.data);
      },

      getPackageSelection() {
        return (
          self.components.get('PackageSelection') ||
          self.addComponent(
            PackageSelectionModel.create({
              id: 'PackageSelection',
              additionalPackages: {},
              activePackages: {},
            })
          )
        );
      },
      getEditButton() {
        return (
          self.components.get('EditButton') ||
          self.addComponent(
            ButtonModel.create({
              id: 'EditButton',
              label: 'basic:change',
              onClickCallback: () => {
                this.setEditMode(false);
                this.enableInputFields();
              },
            })
          )
        );
      },
      getStoreLocations() {
        return (
          self.components.get('StoreLocations') ||
          self.addComponent(
            PhysicalStoreLocationsModel.create({
              id: 'StoreLocations',
              elements: {},
              deleteLocationModal: DeleteLocationModalModel.create({
                id: 'deleteLocationModal',
                modal: ModalModel.create({
                  id: 'modal',
                  showCloseButton: false,
                  opened: false,
                }),
                onAfterDelete: async () => {
                  await self.root.api.partnerLocation.getPartnerLocations(
                    self.root.header.partnerId
                  );
                  this.manageStoreLocations();
                },
              }),
              storeLocationModal: StoreLocationModalModel.create({
                id: 'storeLocationModal',
                onSaveCallback: async () => {
                  await self.root.api.partnerLocation.getPartnerLocations(
                    self.root.header.partnerId
                  );
                  this.manageStoreLocations();
                },
                name: TextInputModel.create({
                  id: 'name',
                  type: 'text',
                  isRequired: true,
                  label: i18n.t('basic:store_name'),
                }),
                getShoppingMallGroup: CheckboxGroupModel.create({
                  id: 'isShoppingMallCheckbox',
                  selectedCheckboxes: {},
                  checkboxes: {
                    isShoppingMall: CheckboxModel.create({
                      id: IS_SHOPPING_MALL,
                      label: 'basic:is_shopping_mall',
                    }),
                  },
                }),
                shoppingMallLocation: DropdownModel.create({
                  id: 'shoppingMallLocation',
                  label: 'basic:shoppingMallLocation',
                  initialValue: '0',
                  placeholder: 'Izaberite grad',
                  options: this.getShoppingMallLocationsOptions(),
                  onChangeCallback: ({ value }: any) => {
                    console.log(this.getShoppingMallOptions(value));
                    const sl = self.components.get('StoreLocations');
                    sl.storeLocationModal.shoppingMall.setOptions(
                      this.getShoppingMallOptions(value)
                    );
                    sl.storeLocationModal.shoppingMall.resetValue();
                  },
                }),
                shoppingMall: DropdownModel.create({
                  id: 'shoppingMall',
                  label: 'basic:shoppingMall',
                  initialValue: '0',
                  placeholder: 'Izaberite tržni centar',
                  onChangeCallback: ({ id }: any) => {
                    const shoppingMall = self.root.data.shoppingMalls.find(
                      (sm: ShoppingMall) => {
                        return sm.id === id;
                      }
                    );
                    const sl = self.components.get('StoreLocations');
                    if (shoppingMall) {
                      sl.storeLocationModal.setAddress(shoppingMall.address);
                      sl.storeLocationModal.syncCoordinates();
                    }
                  },
                }),
                address: TextInputModel.create({
                  id: 'address',
                  isRequired: true,
                  type: 'text',
                  label: i18n.t('basic:store_address'),
                  setValueCallback: debounce(() => {
                    self?.components
                      ?.get?.('StoreLocations')
                      ?.storeLocationModal?.syncCoordinates();
                  }, 300),
                }),
                workingHoursWeekday: TextInputModel.create({
                  id: 'workingHoursWeekday',
                  isRequired: true,
                  type: 'text',
                  label: i18n.t('basic:weekday'),
                  placeholder: 'od xx h do xx hh',
                }),
                workingHoursSaturday: TextInputModel.create({
                  id: 'workingHoursSaturday',
                  isRequired: true,
                  type: 'text',
                  label: i18n.t('basic:saturday'),
                  placeholder: 'od xx h do xx hh',
                }),
                workingHoursSunday: TextInputModel.create({
                  id: 'workingHoursSunday',
                  isRequired: true,
                  type: 'text',
                  label: i18n.t('basic:sunday'),
                  placeholder: 'Ne radimo',
                }),
                phone: TextInputModel.create({
                  id: 'phone',
                  isRequired: true,
                  type: 'text',
                  label: i18n.t('basic:store_contact_phone'),
                }),
                email: TextInputModel.create({
                  id: 'email',
                  isRequired: true,
                  type: 'text',
                  label: i18n.t('basic:store_email'),
                }),
                saveBtn: ButtonModel.create({
                  id: 'saveBtn',
                  label: i18n.t('basic:save'),
                }),
                modal: ModalModel.create({
                  id: 'modal',
                  showCloseButton: true,
                  opened: false,
                }),
                tabs: TabsModel.create({
                  id: 'tabs',
                  selectedTab: 'individualStores',
                  tabs: {
                    individualStores: TabModel.create({
                      id: 'individualStores',
                      name: i18n.t('tabs:individual_stores'),
                    }),
                    locationsTable: TabModel.create({
                      id: 'locationsTable',
                      name: i18n.t('tabs:locations_table'),
                    }),
                  },
                }),
              }),
            })
          )
        );
      },
      getAdministrators() {
        return (
          self.components.get('Administrators') ||
          self.addComponent(
            AdministratorsModel.create({
              id: 'Administrators',
              deleteModal: ConfirmationModalModel.create({
                id: 'deleteModal',
                text: 'basic:are_you_sure',
                modal: ModalModel.create({
                  id: 'modal',
                  opened: false,
                  showCloseButton: true,
                }),
              }),
              modal: ModalModel.create({
                id: 'CreateAdministratorsModal',
                label: 'Add Administrator',
                opened: false,
                showCloseButton: true,
              }),
              isUpdate: false,
              deleteAdminModal: ModalModel.create({
                id: 'DeleteAdministratorModal',
                label: 'Delete Administrator',
                opened: false,
                showCloseButton: true,
              }),
            })
          )
        );
      },
      setIsContractRequestSent(request: boolean) {
        self.isContractRequestSent = request;
      },
      setIsMonthlyRequestSent(request: boolean) {
        self.isMonthlyRequestSent = request;
      },
      setIsPayAsYouGoRequestSent(request: boolean) {
        self.isMonthlyRequestSent = request;
      },
      enableInputFields() {
        const { currentPage } = self.root;
        currentPage.getWebAddress().setDisabled(false);
        currentPage.getContactPhoneResponsiblePerson().setDisabled(false);
        currentPage.getTelenorAgentEmail().setDisabled(false);
        currentPage.getPhoneForConsumersContact().setDisabled(false);
        currentPage.getEmailForConsumersContact().setDisabled(false);
        currentPage.getCompanyNameOnAds().setDisabled(false);
      },
      getCompanyNameModel() {
        return (
          self?.components.get('CompanyName') ||
          self?.addComponent(
            InfoFieldModel.create({
              id: 'CompanyName',
              label: 'company_profile:company_name',
              disabled: true,
              content: self.root.data.partnerDetails.legal_name || '',
            })
          )
        );
      },
      getCompanyAddressModel() {
        return (
          self?.components.get('CompanyAddress') ||
          self?.addComponent(
            InfoFieldModel.create({
              id: 'CompanyAddress',
              label: 'company_profile:company_address',
              disabled: true,
              content: self?.root?.data?.partnerDetails?.address || '',
            })
          )
        );
      },
      getCompanyStreetModel() {
        return (
          self?.components.get('CompanyStreet') ||
          self?.addComponent(
            InfoFieldModel.create({
              id: 'CompanyStreet',
              label: 'company_profile:street',
              disabled: true,
              content: self?.root?.data?.partnerDetails?.street || '',
            })
          )
        );
      },
      getCompanyStreetNumberModel() {
        return (
          self?.components.get('CompanyStreetNumber') ||
          self?.addComponent(
            InfoFieldModel.create({
              id: 'CompanyStreetNumber',
              label: 'company_profile:streetNumber',
              disabled: true,
              content: self?.root?.data?.partnerDetails?.street_no || '',
            })
          )
        );
      },
      getCompanyApartmentNumberModel() {
        return (
          self?.components.get('CompanyApartmentNumber') ||
          self?.addComponent(
            InfoFieldModel.create({
              id: 'CompanyApartmentNumber',
              label: 'company_profile:apartmentNumber',
              disabled: true,
              content: self?.root?.data?.partnerDetails?.app_no || '',
            })
          )
        );
      },
      getCompanyCityModel() {
        return (
          self?.components.get('CompanyCity') ||
          self?.addComponent(
            InfoFieldModel.create({
              id: 'CompanyCity',
              label: 'company_profile:city',
              disabled: true,
              content: self?.root?.data?.partnerDetails?.city || '',
            })
          )
        );
      },
      getCompanyZipModel() {
        return (
          self?.components.get('CompanyZip') ||
          self?.addComponent(
            InfoFieldModel.create({
              id: 'CompanyZip',
              label: 'company_profile:zip',
              disabled: true,
              content: self?.root?.data?.partnerDetails?.zip || '',
            })
          )
        );
      },
      getCompanyPakModel() {
        return (
          self?.components.get('CompanyPak') ||
          self?.addComponent(
            InfoFieldModel.create({
              id: 'CompanyPak',
              label: 'company_profile:pak',
              disabled: true,
              content: self?.root?.data?.partnerDetails?.pak || '',
            })
          )
        );
      },
      getBillingAddressModel() {
        return (
          self?.components.get('BillingAddress') ||
          self?.addComponent(
            InfoFieldModel.create({
              id: 'BillingAddress',
              label: 'company_profile:billing_address',
              disabled: true,
              content: self.root.data.partnerDetails.billing_address || '',
            })
          )
        );
      },
      getBillingCityModel() {
        return (
          self?.components.get('BillingCity') ||
          self?.addComponent(
            InfoFieldModel.create({
              id: 'BillingCity',
              label: 'company_profile:billing_city',
              disabled: true,
              content: self?.root?.data?.partnerDetails?.billing_city || '',
            })
          )
        );
      },

      getBillingStreetModel() {
        return (
          self?.components.get('BillingStreet') ||
          self?.addComponent(
            InfoFieldModel.create({
              id: 'BillingStreet',
              label: 'company_profile:street',
              disabled: true,
              content: self?.root?.data?.partnerDetails?.billing_street || '',
            })
          )
        );
      },
      getBillingStreetNumberModel() {
        return (
          self?.components.get('BillingStreetNumber') ||
          self?.addComponent(
            InfoFieldModel.create({
              id: 'BillingStreetNumber',
              label: 'company_profile:streetNumber',
              disabled: true,
              content:
                self?.root?.data?.partnerDetails?.billing_street_no || '',
            })
          )
        );
      },
      getBillingApartmentNumberModel() {
        return (
          self?.components.get('BillingApartmentNumber') ||
          self?.addComponent(
            InfoFieldModel.create({
              id: 'BillingApartmentNumber',
              label: 'company_profile:apartmentNumber',
              disabled: true,
              content: self?.root?.data?.partnerDetails?.billing_app_no || '',
            })
          )
        );
      },
      getBillingZipModel() {
        return (
          self?.components.get('BillingZip') ||
          self?.addComponent(
            InfoFieldModel.create({
              id: 'BillingZip',
              label: 'company_profile:billing_zip',
              disabled: true,
              content: self?.root?.data?.partnerDetails?.billing_zip || '',
            })
          )
        );
      },
      getBillingPakModel() {
        return (
          self?.components.get('BillingPak') ||
          self?.addComponent(
            InfoFieldModel.create({
              id: 'BillingPak',
              label: 'company_profile:pak',
              disabled: true,
              content: self?.root?.data?.partnerDetails?.billing_pak || '',
            })
          )
        );
      },
      getWindPayModel() {
        return (
          self?.components.get('WindPay') ||
          self?.addComponent(
            InfoFieldModel.create({
              id: 'WindPay',
              label: 'company_profile:wind_pay_supported',
              disabled: true,
            })
          )
        );
      },
      getWindPay() {
        const content =
          self.root.data.partnerDetails.pay === '1'
            ? i18n.t('basic:yes')
            : i18n.t('basic:no');
        this.getWindPayModel().setContent(content);
        return this.getWindPayModel();
      },
      getOfferType() {
        return (
          self?.components.get('OfferType') ||
          self?.addComponent(
            InfoFieldModel.create({
              id: 'OfferType',
              label: 'company_profile:offer_type',
              disabled: true,
              content: self?.root?.data?.contractDetails?.name || '',
            })
          )
        );
      },
      getShoppingMallOptions: function (location: string) {
        return self.root.data.shoppingMalls
          .filter((mall: ShoppingMall) => {
            return mall.location_group === location;
          })
          .map((mall: ShoppingMall) => {
            return {
              data: {},
              value: 'TC ' + mall.name + ', ' + mall.address,
              id: mall.id,
            };
          });
      },
      getShoppingMallLocationsOptions() {
        const obj: any = {};
        self.root.data.shoppingMalls.forEach((mall: ShoppingMall) => {
          obj[mall.location_group] = DropdownOptionModel.create({
            id: mall.location_group,
            value: mall.location_group,
          });
        });
        return obj;
      },
      getShoppingMallInitialValue() {
        return '0';
      },
      getPibModel() {
        return (
          self?.components.get('Pib') ||
          self?.addComponent(
            InfoFieldModel.create({
              id: 'Pib',
              label: 'company_profile:pib',
              disabled: true,
              content: self.root.data.partnerDetails.pib || '',
            })
          )
        );
      },
      getMbrModel() {
        return (
          self?.components.get('Mbr') ||
          self?.addComponent(
            InfoFieldModel.create({
              id: 'Mbr',
              label: 'company_profile:mbr',
              disabled: true,
              content: self.root.data.partnerDetails.mbr || '',
            })
          )
        );
      },
      getMbr() {
        this.getMbrModel().setContent(self.root.data.partnerDetails.mbr);
        return this.getMbrModel();
      },
      checkIfIsValid(inputField: any) {
        const children = [inputField];
        let valid = true;
        for (const child of children) {
          child.runValidators();
          if (child.isInvalid) {
            valid = false;
          }
        }
        return valid;
      },

      getFileInputs() {
        if (!self?.fileInputs || !self?.fileInputs?.length) {
          this.initFileInputs();
        }
        return self.fileInputs;
      },
      getRequestTariffChangeButton() {
        return (
          self.components.get('RequestTariffChangeButton') ||
          self.addComponent(
            ButtonModel.create({
              id: 'RequestTariffChangeButton',
              label: 'Pošalji zahtev',
              isEnabledFunc: () =>
                !self.isRequestApproved || self.root.data.isContractExpired,
            })
          )
        );
      },
      getTariffAdditionButton() {
        return (
          self.components.get('TariffAdditionButton') ||
          self.addComponent(
            ButtonModel.create({
              isEnabledFunc: () => !self.root.data.hasPurchases,
              id: 'TariffAdditionButton',
              label: 'Aktiviraj dodatak',
            })
          )
        );
      },
      initFileInputs() {
        Array.from(Array(1).keys()).forEach((index: number) => {
          self.addComponent(
            FileInputModel.create({
              id: `FileInput${index}`,
              label: 'Za koje lokacije ponuda važi',
              maxNumberOfFiles: 1,
              isRequired: false,
              onAdd: () => {
                self.root.api.partner.uploadImage(self.root.header.partnerId);
              },
            })
          );
        });
      },
    };
  })
  .named('CompanyProfileModel');

export type CompanyProfileModelType = Instance<typeof CompanyProfileModel>;
