import { IAnyModelType, types, getRoot } from 'mobx-state-tree';
import { SEGMENTED } from '../../../../constants';
import {
  Ad,
  AdUpdate,
  CreateOfferPageModel,
  DurationModel,
  ModalModel,
  RequestNewAddon,
  RootType,
  TextAreaInputModel,
  TextInputModel,
} from '../../../../internal';
import { dateFormatter } from '../../../../utils/date';
import { validateTitle, validateURL } from '../../../../validators/validators';
import { CODE_TYPE_PERSONALIZED, SUBMITTED } from '../../../../constants/ad';
import {
  getCategoryIdFromSubcategory,
  getSubcategoryIdFromSubcategory,
} from '../../../../utils/methods';

export const OfferInfoModel: IAnyModelType = types
  .model({
    parent: types.maybe(
      types.reference(types.late(() => CreateOfferPageModel))
    ),
  })
  .views((self) => {
    return {
      get root(): RootType {
        return getRoot(self);
      },
      get isUserTargetingEnabled(): boolean {
        return self.parent?.getOfferTypeModel().value === SEGMENTED;
      },
      get categories(): [] {
        const selectedOptions =
          self?.parent?.components.get('CategoryPick')?.selectedOptionsArray;
        const categories: any = [];
        if (selectedOptions.length > 0) {
          selectedOptions.map((selectedOption: string) => {
            const categoryId = getCategoryIdFromSubcategory(selectedOption);
            const subcategoryId =
              getSubcategoryIdFromSubcategory(selectedOption);
            categories.push({
              category_id: categoryId,
              sub_category_id: subcategoryId === 'all' ? null : subcategoryId,
            });
          });
        }

        return categories;
      },
      get categoryId(): string {
        return self?.parent?.components.get('CategoryPick')?.value;
      },
      get subcategoryId(): string {
        return self?.parent?.components.get('SubCategoryPick')?.value;
      },
      get packagePick(): string {
        return self?.parent?.components.get('PackagePick')?.value;
      },
      get type(): string {
        return self.parent?.components.get('OfferType')?.value === SEGMENTED
          ? 'special'
          : 'standard';
      },
      get yettelSegments(): string {
        return self?.parent?.components.get('YettelSegments')?.value;
      },
      get url(): string {
        return self?.parent?.components.get('OfferLinkUrl')?.value;
      },
      get offerName(): string {
        return self?.parent?.components.get('OfferName')?.value;
      },
      get offerDescription(): string {
        return self?.parent?.components?.get('OfferDescription')?.value;
      },
      get offerTerms(): string {
        return self?.parent?.components?.get('OfferTerms')?.value;
      },
      get keywords(): string {
        return self?.parent?.components?.get('Keywords')?.value;
      },
      get offerType(): string {
        return self?.parent?.components?.get('OfferType')?.value;
      },
      get unsegmentedOfferType(): boolean {
        return this.offerType !== SEGMENTED;
      },
      get visualType(): string {
        return self?.parent?.components?.get('AdRadioButtons')?.value;
      },
      get locationType(): string {
        return self?.parent?.components?.get('LocationPick')?.value;
      },
      get storeLocations(): string {
        return self?.parent?.components?.get('PickStores')?.idsStr;
      },
      get voucherDuration() {
        const component = self?.parent?.components?.get('VoucherDuration');
        if (self?.parent?.isWithoutVoucher) {
          return {};
        }
        return {
          date_code_start: dateFormatter(component.fromDate),
          date_code_end: dateFormatter(component.toDate),
        };
      },
      get discountPercentValue(): number | null {
        if (self?.parent?.components.get('DiscountPercent')?.value == '') {
          return null;
        } else {
          return self?.parent?.components.get('DiscountPercent')?.value;
        }
      },
      get codeCount(): number {
        return self?.parent?.components.get('CodeCount')?.value;
      },
      get recommendedValue(): number {
        return self?.parent?.components.get('RecommendedDropdown')?.value;
      },
      get codeData() {
        const currentPage = self.parent;
        const enterCodesModel =
          self.parent?.components?.get('UploadCodesModel');
        if (currentPage?.isWithoutVoucher) {
          return {
            with_code: 'nocode',
            code_type: 'numeric', // placeholder to pass BE validation, should not be applicable
          };
        }
        const pickedCodeTypes =
          enterCodesModel?.checkboxContainer?.selectedIdsStr;

        let withCode = enterCodesModel?.codeTypeDropdown?.value;
        // this is an initial value
        if (self?.parent?.getOfferSteps().isFirstSelected) {
          withCode = null;
        }

        return {
          with_code: withCode,
          ...(pickedCodeTypes
            ? { code_type: pickedCodeTypes }
            : { code_type: 'numeric' }), // same as above ( in case of 'common' code)
          common_code: enterCodesModel?.textInputField?.value,
          ...(withCode === CODE_TYPE_PERSONALIZED && {
            ref: enterCodesModel.uploadCodesRef,
          }),
        };
      },
      calculateAdLocationAndTargeting() {
        const currentPage = self.parent;
        if (!currentPage) {
          return {};
        }
        // was currentPage.standardPackageSelected, see LOY-393
        if (this.unsegmentedOfferType) {
          return {
            ref: currentPage.getEnterCodesModel().uploadCodesRef,
          };
        }
        const addons: RequestNewAddon[] = [];

        const purchased_extension_id = currentPage.selectedPurchasedExtensionId;
        let contract_extension = null;
        if (purchased_extension_id !== null) {
          const additionalLocation = currentPage.getAdditionalLocation();
          const locationsAddon: RequestNewAddon = {
            type: 'location',
            contract_addon_id: additionalLocation.selectedContractAddon,
            count: additionalLocation.numberOfAdditionalLocations,
          };

          if (locationsAddon.contract_addon_id) {
            addons.push(locationsAddon);
          }

          const additionalUsers = currentPage.getAdditionalUsers();
          const usersAddon: RequestNewAddon = {
            type: 'target',
            contract_addon_id: additionalUsers.selectedContractAddon,
            count: Number(additionalUsers.numberOfAdditionalUsersField.value),
          };

          if (usersAddon.contract_addon_id) {
            addons.push(usersAddon);
          }
        } else {
          contract_extension =
            currentPage?.getPackagePickModel()?.selectedOption
              ?.contractExtensionId;
        }

        return this.isUserTargetingEnabled
          ? {
              user_locations: currentPage.getLocationTagGroup().valsStr,
              gender: currentPage.getGenderDropdown().value.trim(),
              age_from: parseInt(currentPage.getFromAge().value),
              age_to: parseInt(currentPage.getToAge().value),
              similar_only: parseInt(
                currentPage.getUsersPickedSimilarOffersDropdown().value
              ),
              user_contract_type: currentPage.getUserSegments().selectedIdsStr,
              spender_type:
                currentPage.getMobileNetworkSpending().selectedIdsStr,
              territory_type: currentPage.getAreaDropdown()?.value?.trim?.(),
              max_target: this.calculateMaxTarget(),
              ref: currentPage.getEnterCodesModel().uploadCodesRef,
              // purchased_extension_id: purchased_extension_id,
              contract_extension: contract_extension,
              contract_addons: addons,
            }
          : {};
      },

      calculateMaxTarget() {
        const { currentPage } = getRoot(self) as any;
        const userTargetsCount: number = currentPage.userTargetsCount;
        // commented out temporarily, see LOY-393
        /*const packageUsersAvailable = currentPage.packageUsersAvailable;
        if (userTargetsCount < packageUsersAvailable) {
          return userTargetsCount;
        }
        const value =
          packageUsersAvailable +
          Number(
            currentPage?.getAdditionalUsers()?.numberOfAdditionalUsersField
              ?.value
          );
        return value;*/
        return userTargetsCount;
      },
      get offerForSubmit(): Ad {
        return {
          ad_id: this.root.router.queryParams.updateAd
            ? this.root.router.queryParams.updateAd
            : '',
          partner_id: this.root.header.partnerId,
          type: this.type,
          contract_id: self?.parent?.contractId,
          categories: this.categories,
          ...(self?.parent?.isYettelCategorySelected
            ? { user_segment: this.yettelSegments }
            : {}),
          url: this.url,
          name: this.offerName,
          discount_value: self?.parent?.adLoweredPriceRSD
            ? self?.parent?.adLoweredPriceRSD
            : null,
          original_price: self?.parent?.adRegularPriceRSD
            ? self?.parent?.adRegularPriceRSD
            : null,
          discount_percentage: this.discountPercentValue,
          description: this.offerDescription,
          discount_description: self?.parent?.adDiscount,
          conditions: this.offerTerms,
          keywords: this.keywords,
          // TODO remove this property when backend is ready, now sending an empty string " "!!!
          cost_by: this.offerType,
          visual_type: this.visualType,
          store_location_type: this.locationType,
          store_locations: this.storeLocations,
          date_start: dateFormatter(self?.parent?.getOfferDuration().fromDate),
          date_end: dateFormatter(self?.parent?.getOfferDuration().toDate),
          // makes sense only if the voucher is selected in the beginning
          ...this.voucherDuration,
          code_count: Number(self?.parent?.codesNeeded) ?? null,
          ...this.codeData,
          // This part of the form is not valid if the standard package is selected
          // so in that case an empty object will be added to the request instead
          ...this.calculateAdLocationAndTargeting(),
          recommended: this.recommendedValue,
          ...(self?.parent?.getOfferSteps().isThirdSelected
            ? { status: SUBMITTED }
            : {}),
          status: this.root.data?.adDetails?.status,
        } as any as Ad;
      },
      get updateAdId(): string {
        return this?.root?.router?.queryParams?.updateAd;
      },
      // for Ad Update POST
      get adUpdateForSubmit(): AdUpdate {
        return {
          ad_id: this.updateAdId,
          image0: '',
          image1: '',
          image2: '',
          image3: '',
          categories: this.categories,
          name: this.offerName,
          discount_value: self?.parent?.adLoweredPriceRSD
            ? self?.parent?.adLoweredPriceRSD + ''
            : null,
          discount_percentage: this.discountPercentValue,
          original_price: self?.parent?.adRegularPriceRSD
            ? self?.parent?.adRegularPriceRSD + ''
            : null,
          description: this.offerDescription,
          discount_description: self?.parent?.adDiscount,
          conditions: this.offerTerms,
          keywords: this.keywords,
          date_start: dateFormatter(self?.parent?.getOfferDuration().fromDate),
          date_end: dateFormatter(self?.parent?.getOfferDuration().toDate),
          ...this.voucherDuration,
          url: this.url,
          visual_type: this.visualType,
          store_location_type: this.locationType,
          recommended: this.recommendedValue,
        };
      },
    };
  })
  .actions((self) => {
    return {
      getOfferNameModel() {
        return (
          self?.parent?.components.get('OfferName') ||
          self?.parent?.addComponent(
            TextInputModel.create({
              id: 'OfferName',
              label: 'basic:offer_title',
              isRequired: true,
              step: 1,
              validators: {
                validateTitle,
              },
            })
          )
        );
      },
      getLoweredPriceRSDModel() {
        return (
          self?.parent?.components.get('LoweredPriceRSD') ||
          self?.parent?.addComponent(
            TextInputModel.create({
              id: 'LoweredPriceRSD',
              label: 'basic:lowered_price_rsd',
              type: 'number',
              isRequired: false,
              step: 1,
              setValueCallback: () => {
                this.getRegularPriceRSDModel().setCurrentMessage('');
              },
            })
          )
        );
      },
      getRegularPriceRSDModel() {
        return (
          self?.parent?.components?.get('RegularPriceRSD') ||
          self?.parent?.addComponent(
            TextInputModel.create({
              id: 'RegularPriceRSD',
              label: 'basic:regular_price_rsd',
              type: 'number',
              step: 1,
              setValueCallback: () => this?.getDiscountModel()?.runValidators(),
            })
          )
        );
      },
      getDiscountPercent() {
        return (
          self?.parent?.components?.get('DiscountPercent') ||
          self?.parent?.addComponent(
            TextInputModel.create({
              id: 'DiscountPercent',
              label: 'basic:discount_numeric_value',
              type: 'number',
              step: 1,
              showSideSign: true,
              sign: '%',
              isRequired: false,
            })
          )
        );
      },
      getDiscountModel() {
        return (
          self?.parent?.components.get('Discount') ||
          self?.parent?.addComponent(
            TextInputModel.create({
              id: 'Discount',
              label: 'basic:discount',
              setValueCallback: () => {
                this?.getLoweredPriceRSDModel()?.runValidators();
                this?.getRegularPriceRSDModel()?.runValidators();
              },
            })
          )
        );
      },
      getOfferDescriptionModel() {
        return (
          self?.parent?.components.get('OfferDescription') ||
          self?.parent?.addComponent(
            TextAreaInputModel.create({
              id: 'OfferDescription',
              label: 'basic:offer_description',
              isRequired: true,
              step: 1,
            })
          )
        );
      },
      getOfferTerms() {
        return (
          self?.parent?.components.get('OfferTerms') ||
          self?.parent?.addComponent(
            TextAreaInputModel.create({
              id: 'OfferTerms',
              label: 'offer:offer_terms',
              isRequired: true,
              step: 1,
            })
          )
        );
      },
      getKeywords() {
        return (
          self?.parent?.components.get('Keywords') ||
          self?.parent?.addComponent(
            TextAreaInputModel.create({
              id: 'Keywords',
              label: 'basic:keywords_label',
              isRequired: false,
              step: 1,
              showButtons: false,
              placeholder: 'basic:keywords_placeholder',
            })
          )
        );
      },
      getOfferDuration() {
        return (
          self?.parent?.components.get('OfferDuration') ||
          self?.parent?.addComponent(
            DurationModel.create({
              id: 'OfferDuration',
              fromDate: new Date(),
              toDate: new Date(),
              maxDuration: 31,
              disabledDates: [],
              minDate: new Date(),
              onChangeCallback: () => {
                if (self?.parent?.isUpdateMode) {
                  return;
                }
                const offerDuration = this.getOfferDuration();
                const fromDate = offerDuration.fromDate
                  ? offerDuration.fromDate
                  : new Date();
                const toDate = offerDuration.toDate
                  ? offerDuration.toDate
                  : new Date();
                if (
                  fromDate !== this.getOfferDuration().fromDate &&
                  toDate !== this.getOfferDuration().toDate
                ) {
                  this.getOfferDuration().setFromDate(fromDate);
                  this.getOfferDuration().setToDate(toDate);
                }
              },
              modal: ModalModel.create({
                id: 'OfferDurationModal',
                label: 'Offer Duration',
                opened: false,
                showCloseButton: true,
              }),
            })
          )
        );
      },
      getVoucherDuration() {
        return (
          self?.parent?.components.get('VoucherDuration') ||
          self?.parent?.addComponent(
            DurationModel.create({
              id: 'VoucherDuration',
              minDate: new Date(),
              fromDate: new Date(),
              toDate: new Date(),
              modal: ModalModel.create({
                id: 'VoucherDurationModal',
                label: 'Voucher Duration',
                opened: false,
                showCloseButton: true,
              }),
            })
          )
        );
      },
      getOfferLinkUrl() {
        return (
          self?.parent?.components.get('OfferLinkUrl') ||
          self?.parent?.addComponent(
            TextInputModel.create({
              id: 'OfferLinkUrl',
              isRequired: self?.parent?.directLinkToSiteShouldBeRequired,
              validators: {
                validateURL: validateURL,
              },
              step: 1,
            })
          )
        );
      },
    };
  })
  .named('OfferInfoModel');
